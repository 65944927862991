// carfinder.js

/**** Funtion statuses values */
/**********
val 0 = startup position
val 1 = search ended
**********/

// import fetch from '../../fetch_with_timeout'

window.refresh_statistics_on_load = () => {
    const data_post = { };
    const auth_token = localStorage.getItem('auth_token');
    fetch("/es/carfinder/api/v1/load_statistics",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(data_post),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => { })
    .catch((error) => { })
}



// functions froM API
window.api_v1_search_car = (data_post) => {
    spin_in();
    const auth_token = localStorage.getItem('auth_token');

    fetch("/es/carfinder/api/v1/search_car",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(data_post),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        console.log(data)
        console.log(JSON.stringify(data));


        if( data.status == 200 ) {
            let str = '';
            str += '<div class="col-12">';
            str += '<h4>Resultados <span class="span-res-title">(El vehículo entró hace ' + data.data.startup + ')</span></h4>';
            str += '</div>';

            const conos = data.data.conos;

            // Videos management
            let videos_present_str = '';
            const videos = conos[0].videos;
            if( videos.length != 0 ) {
                for (let i=0; i<videos.length; i++) {
                    videos_present_str += '<video class="video-fluid z-depth-1" style="width: 100%;" autoplay loop controls muted>';
                    videos_present_str += '<source id="video-source" src="' + videos[i].url + '" type="video/mp4" />';
                    videos_present_str += '</video>'
                    videos_present_str += '<p>' + videos[i].timing + '</p>';
                }
                $(".modal-body-present").html(videos_present_str);

                // Print result if has video
                str += '<div class="col-6">';
                for (let i=0; i<conos.length; i++) {
                
                    str += '<div class="result-conos has-video" style="background-color: ' + return_css_color_from_cono_color(conos[i].color) + ';">';
                    str += '<p><i class="fas fa-car"></i> ' + conos[i].cono + '</p> ';
                    str += '<p><i class="fas fa-video"></i> ' + conos[i].location + '</p>';
                    str += '</div>';
                }
                str += '</div>'

            } else {
                str += '<div class="col-6">';
                for (let i=0; i<conos.length; i++) {
                    
                    str += '<div class="result-conos" style="background-color: ' + return_css_color_from_cono_color(conos[i].color) + ';">';
                    str += '<p><i class="fas fa-car"></i> ' + conos[i].cono + '</p> ';
                    str += '<p>' + conos[i].location + '</p>';
                    str += '</div>';
                }
                str += '</div>'
            }
            


            const history = data.data.history;
            str += '<div class="col-6 print-history">';
            //str += '<h5>Historial</h5>';
            for (let j=0; j<history.length; j++) {
                if ( history[j].video == null ) {
                    str += '<p style="margin-bottom: 2px;"><button type="button" class="btn btn-secondary btn-sm btn-block">' + history[j].step + '</button></p>';
                } else {
                    str += '<p style="margin-bottom: 2px;"><button type="button" class="btn btn-secondary btn-sm btn-block history-has-video" video-url="' + history[j].video.url + '" video-timing="' + history[j].video.timing + '"><i class="fas fa-video"></i> ' + history[j].step + '</button></p>';
                }
            }
            str += '</div>';

            // // Videos history management
            // let videos_history_str = '';
            // if( videos_his.length != 0 ) {
            //     for (let i=0; i<videos_his.length; i++) {
            //         videos_history_str += '<video class="video-fluid z-depth-1" style="width: 100%;" autoplay loop controls muted>';
            //         videos_history_str += '<source id="video-source" src="' + videos_his[i].url + '" type="video/mp4" />';
            //         videos_history_str += '</video>'
            //         videos_history_str += '<p>' + videos_his[i].timing + '</p>';
            //     }
            // }
            // $(".modal-body-history").html(videos_history_str);


            spin_out();
            $(".search-display").html(str);
            
            $(".app-display").hide();
            $(".search-display").show();


        } else if ( data.status == 422 ) {
            spin_out();
            Swal.fire({
                icon: 'warning',
                title: 'Cono no activado',
                text: 'El cono no está activado en el sistema'
            })
        } else if ( data.status == 500 ) {
            spin_out();
            sweetalert_error('Error de ejecución', 'Error code: E003', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            spin_out();
            sweetalert_error_401();
        }
        
    })
    .catch((error) => {
        spin_out();
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E002', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
    

    $("#function-status").val('1');
    restore_after_search();
}


// functions from API
window.api_v1_activate_car = (data_post) => {
    spin_in();
    const auth_token = localStorage.getItem('auth_token');
    fetch("/es/carfinder/api/v1/activate_car",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(data_post),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        console.log(data);
        console.log(JSON.stringify(data));

        if( data.status == 200 ) {
            localStorage.setItem('last_activation', JSON.stringify({"cono": { "id": data.data.cono.id, "number": data.data.cono.number, "color": data.data.cono.color }, "use": { "id": data.data.use.id } }));
            spin_out();
            Swal.fire({
                icon: 'success',
                title: 'Correcto',
                text: 'El cono se ha activado correctamente en la planta 0'
            })

        } else if ( data.status == 421 ) {
            spin_out();
            Swal.fire({
                icon: 'warning',
                title: 'Cono en uso',
                html: '<p><strong>Situado en '+data.data.location+'</strong></p><p>El cono esta en uso.<br>Puede <strong>Cancelar</strong> esta operacion o <strong>Forzar Cierre</strong> y reactivar de nuevo este cono que quedará situado en <strong>Planta 0</strong></p>',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: 'Forzar Cierre',
            })
            .then((result) => {
                spin_out();
                if (result.isConfirmed) {
                    api_v1_reactivate_car(data_post);
                }
            })

        } else if ( data.status == 422 ) {
            spin_out();
            Swal.fire({
                icon: 'warning',
                title: 'Cono no cerrado',
                html: '<p>El cono solicitado tiene su último uso NO cerrado pero no se encuentra.<br>Puede <strong>Cancelar</strong> esta operacion o <strong>Forzar Cierre</strong> y reactivar de nuevo este cono que quedará situado en <strong>Planta 0</strong></p>',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: 'Forzar Cierre',
            })
            .then((result) => {
                spin_out();
                if (result.isConfirmed) {
                    api_v1_reactivate_car(data_post);
                }
            })
        } else if ( data.status == 500 ) {
            spin_out();
            sweetalert_error('Error de ejecución', 'Error code: E005', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
            spin_out();
        } else if ( data.status == 401 ) {
            sweetalert_error_401();
        }
        
    })
    .catch((error) => {
        spin_out();
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E004', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
    

    $("#function-status").val('1');
    restore_after_search();
}


// functions from API
window.api_v1_reactivate_car = (data_post) => {

    const auth_token = localStorage.getItem('auth_token');
    fetch("/es/carfinder/api/v1/reactivate_car",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(data_post),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        if( data.status == 200 ) {
            localStorage.setItem('last_activation', JSON.stringify({"cono": { "id": data.data.cono.id, "number": data.data.cono.number, "color": data.data.cono.color }, "use": { "id": data.data.use.id } }));
            Swal.fire({
                icon: 'success',
                title: 'Reactivación OK',
                text: 'Se ha cerrado el uso anterior y se ha reactivado correctamente en la planta 0'
            })
        } else if ( data.status == 400 ) {
            sweetalert_error('Error de backend', 'Error code: E008', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 500 ) {
            sweetalert_error('Error de ejecución', 'Error code: E007', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            sweetalert_error_401();
        }
    })
    .catch((error) => {
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E006', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

}

// functions from API
window.api_v1_deactivate_car = (data_post) => {
    spin_in();
    const auth_token = localStorage.getItem('auth_token');
    fetch("/es/carfinder/api/v1/deactivate_car",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(data_post),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        console.log(data);
        console.log(JSON.stringify(data));

        if( data.status == 200 ) {
            spin_out();
            Swal.fire({
                icon: 'success',
                title: 'Correcto',
                text: 'El cono se ha desactivado correctamente.'
            })
        } else if ( data.status == 201 ) {
            spin_out();
            Swal.fire({
                icon: 'success',
                title: 'Correcto',
                text: 'El pack se ha desactivado correctamente.'
            })
        } else if ( data.status == 400 ) {
            spin_out();
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Se ha producido un error al desactivar el vehículo. ' + data.error
            })
        } else if ( data.status == 422 ) {
            spin_out();
            Swal.fire({
                icon: 'warning',
                title: 'Error',
                text: data.error
            })
        } else if ( data.status == 500 ) {
            spin_out();
            sweetalert_error('Error de ejecución', 'Error code: E010', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            spin_out();
            sweetalert_error_401();
        }
        
    })
    .catch((error) => {
        spin_out();
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E009', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

    $("#function-status").val('1');
    restore_after_search();
}

// Function previous activate pack
window.api_v1_preactivate_pack = (cono, color) => {
    
    const last_activation = JSON.parse(localStorage.getItem('last_activation'));

    if ( last_activation != null ) {
        console.log('last_activation NO ES NULL');

        Swal.fire({
            icon: 'info',
            title: 'Activacion Pack',
            html: '<p>Ha solicitado la activacion de:<br><span class="alert-pack" style="background-color: ' + return_css_color_from_cono_color(last_activation.cono.color) + ';"><i class="fas fa-car fa-1x"></i> ' + last_activation.cono.number + '</span><br>con el cono que acaba de marcar:<br><span class="alert-pack" style="background-color: ' + return_css_color_from_cono_color(color) + ';"><i class="fas fa-car fa-1x"></i> ' + cono + '</span></p><h5>¿Desea Continuar?</h5>',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            confirmButtonText: 'Crear Pack',
        })
        .then((result) => {
            if (result.isConfirmed) {
                api_v1_activate_pack(cono, color);
            } else {
                $("#function-status").val('1');
                restore_after_search();
            }
        })
        .catch((error) => {
            console.log(error)
        })
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Error',
            text: 'No tiene ningún cono para activar en pack. Active un cono primero.'
        })
        $("#function-status").val('1');
        restore_after_search();
    }

}

// Function confirm activate pack
window.api_v1_activate_pack = (cono, color) => {
    spin_in();
    const auth_token = localStorage.getItem('auth_token');
    const last_activation = JSON.parse(localStorage.getItem('last_activation'));
    const data_post = { cono: cono, color: color, use_id: last_activation.use.id }
    fetch("/es/carfinder/api/v1/activate_pack",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(data_post),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        console.log(data);
        console.log(JSON.stringify(data));

        if( data.status == 200 ) {
            localStorage.removeItem('last_activation');
            spin_out();
            Swal.fire({
                icon: 'success',
                title: 'Correcto',
                text: 'El cono se ha activado correctamente en la planta 0 y el pack se ha creado correctamente'
            })
        } else if ( data.status == 422 ) {
            spin_out();
            Swal.fire({
                icon: 'warning',
                title: 'Cono no cerrado',
                html: '<p>El cono solicitado para añadir al pack tiene su último uso NO cerrado.<br>Puede <strong>Cancelar</strong> esta operacion o <strong>Forzar Cierre</strong> y reactivar de nuevo este cono</p>',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                showConfirmButton: true,
                confirmButtonText: 'Forzar Cierre',
            })
            .then((result) => {
                spin_out();
                if (result.isConfirmed) {
                    api_v1_reactivate_pack(cono, color);
                }
            })
        } else if ( data.status == 500 ) {
            spin_out();
            sweetalert_error('Error de ejecución', 'Error code: E005', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            spin_out();
            sweetalert_error_401();
        }
        
    })
    .catch((error) => {
        spin_out();
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E004', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

    $("#function-status").val('1');
    restore_after_search();

}

// functions from API
window.api_v1_reactivate_pack = (cono, color) => {

    const auth_token = localStorage.getItem('auth_token');
    const last_activation = JSON.parse(localStorage.getItem('last_activation'));
    const data_post = { cono: cono, color: color, use_id: last_activation.use.id }
    fetch("/es/carfinder/api/v1/reactivate_pack",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(data_post),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        if( data.status == 200 ) {
            //localStorage.setItem('last_activation', JSON.stringify({"cono": { "id": data.data.cono.id, "number": data.data.cono.number, "color": data.data.cono.color }, "use": { "id": data.data.use.id } }));
            localStorage.removeItem('last_activation');
            Swal.fire({
                icon: 'success',
                title: 'Reactivación OK',
                text: 'Se ha cerrado el uso anterior y se ha reactivado correctamente en la planta 0'
            })
        } else if ( data.status == 400 ) {
            sweetalert_error('Error de backend', 'Error code: E008', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 500 ) {
            sweetalert_error('Error de ejecución', 'Error code: E007', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            sweetalert_error_401();
        }
    })
    .catch((error) => {
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E006', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

}


// Funtion for relocation puerposes
window.api_v1_relocate_car = (data_post) => {
    spin_in();
    if ($("#function-status").val().toString() == "1") {
        const last_relocation = JSON.parse(localStorage.getItem('last_relocation'));
        data_post.push(last_relocation);
    }
    // Update last relocation
    localStorage.setItem('last_relocation', JSON.stringify(data_post[0]));
    
    const auth_token = localStorage.getItem('auth_token');

    const data_send = {conos: data_post};

    fetch("/es/carfinder/api/v1/relocate_car",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(data_send),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {

        console.log(data);
        const alert = return_relocation_alert_colors(data.alert);

        if( data.status == 200 ) {
            spin_out();
            let html_str = '<div class="col-12">' + data.info + '</div>';
            $(".display-relocations").css('background-color', alert);
            $(".display-relocations").html(html_str);
            $(".display-relocations").slideDown();

            setTimeout(() => {
                $(".display-relocations").fadeOut();
            }, 2000);

        } else if ( data.status == 400 ) {
            spin_out();
            sweetalert_error('Error de backend', 'Error code: E011', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        }

        restore_screen_after_relocation();

    })
    .catch((error) => {
        spin_out();
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E012', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

}

window.api_v1_get_occupancy = () => {

    spin_in();
    const auth_token = localStorage.getItem('auth_token');
    fetch("/es/carfinder/api/v1/get_occupancy",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        console.log(data);
        console.log(JSON.stringify(data));

        if( data.status == 200 ) {
            spin_out();
            const occupancy = data.occupancy; // [floor_0, backyard, floor_1, floor__1, floor__2, vn_vo]
            let html_text = '';
            html_text += '<p><strong>Planta 0:</strong> ' + occupancy[0] + ' <i class="fas fa-car"></i></p>';
            //html_text += '<p><strong>Planta 0:</strong> ' + occupancy[0] + ' <i class="fas fa-car"></i></p>';
            html_text += '<p><strong>Planta +1:</strong> ' + occupancy[2] + ' <i class="fas fa-car"></i></p>';
            html_text += '<p><strong>Planta -1:</strong> ' + occupancy[3] + ' <i class="fas fa-car"></i></p>';
            html_text += '<p><strong>Planta -2:</strong> ' + occupancy[4] + ' <i class="fas fa-car"></i></p>';
            html_text += '<p><strong>VN VO:</strong> ' + occupancy[5] + ' <i class="fas fa-car"></i></p>';

            Swal.fire({
                icon: 'success',
                title: 'Ocupación',
                html: html_text
            })
        } else if ( data.status == 500 ) {
            spin_out();
            sweetalert_error('Error de ejecución', 'Error code: E013', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            spin_out();
            sweetalert_error_401();
        }
        
    })
    .catch((error) => {
        spin_out();
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E009', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

}


window.api_v1_conos_last_uses = () => {

    spin_in();
    const auth_token = localStorage.getItem('auth_token');
    fetch("/es/carfinder/api/v1/conos_last_uses",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        console.log(data);

        if( data.status == 200 ) {
            spin_out();

            const red = data.data.red;
            const yellow = data.data.yellow;
            const green = data.data.green;
            const blue = data.data.blue;

            let html_red = '';
            for(let i=0; i < red.length; i++) {
                const red_obj = red[i];
                html_red += '<p><span>' + red_obj.number + '</span> ' + red_obj.time + '</p>';
            }

            let html_yellow = '';
            for(let i=0; i < yellow.length; i++) {
                const yellow_obj = yellow[i];
                html_yellow += '<p><span>' + yellow_obj.number + '</span> ' + yellow_obj.time + '</p>';
            }

            let html_green = '';
            for(let i=0; i < green.length; i++) {
                const green_obj = green[i];
                html_green += '<p><span>' + green_obj.number + '</span> ' + green_obj.time + '</p>';
            }

            let html_blue = '';
            for(let i=0; i < blue.length; i++) {
                const blue_obj = blue[i];
                html_blue += '<p><span>' + blue_obj.number + '</span> ' + blue_obj.time + '</p>';
            }

            $("#red").html(html_red);
            $("#yellow").html(html_yellow);
            $("#green").html(html_green);
            $("#blue").html(html_blue);


        } else if ( data.status == 500 ) {
            spin_out();
            sweetalert_error('Error de ejecución', 'Error code: E014', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            spin_out();
            sweetalert_error_401();
        }
        
    })
    .catch((error) => {
        spin_out();
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E009', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

}

window.api_v1_strange_movements = (date) => {

    spin_in();
    const auth_token = localStorage.getItem('auth_token');
    fetch("/es/carfinder/api/v1/strange_movements",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify({"date": date}),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        console.log(data);

        if( data.status == 200 ) {
            spin_out();

            const movements = data.movements;

            let html = '';

            for(let i=0; i<movements.length; i++){
                const movement = movements[i];

                //html = '';

                html += '<div class="row line-wrapper">';
                html += '<div class=" car-icon">' + movement.tag_number + '<br>';
                html += '<i class="fas fa-car fa-1x" style="color: ' + return_css_color_from_cono_color(movement.tag_color) + ';"></i>';
                html += '</div>';
                html += '<div class="timeline"></div>';
                html += '<svg height="20" width="120%" class="svg-wrapper">';

                const moves = movement.moves;
                for(let j=0; j<moves.length; j++) {
                    const move = moves[j];
                    const calculated_x = helper_calculate_x_distance_circle(j, move.distance);
                    html += '<circle cx="'+ calculated_x +'" cy="10" r="10" fill="rgb(31, 53, 94)" />';
                }

                html += '</svg>';

                for(let j=0; j<moves.length; j++) {
                    const move = moves[j];
                    let distance = move.distance
                    const calculated_x = helper_calculate_x_distance_text(j, distance);
                    html += '<div class="step-floor-number" style="left: '+ calculated_x +'px">'+ move.location +'</div>';
                    html += '<div class="step-step-time" style="left: '+ calculated_x +'px">'+ move.time +'</div>';
                    html += helper_print_distance(j, distance);
                }
                html += '</div>';

                
                
            }
            $(".result-wrapper").html(html);
            $(".step-current-date").text(data.current_date);
            
        } else if ( data.status == 500 ) {
            spin_out();
            sweetalert_error('Error de ejecución', 'Error code: E014', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            spin_out();
            sweetalert_error_401();
        }
        
    })
    .catch((error) => {
        spin_out();
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E009', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

}

window.helper_calculate_x_distance_text = (j, distance) => {
    let repeat = j+1;
    return (repeat*100);
}

window.helper_calculate_x_distance_circle = (j, distance) => {
    return (j*100)+35;
}

window.helper_print_distance = (j, distance) => {
    let repeat = j+1;
    let left = (repeat*100)+53;

    let html = '';
    if (distance != 0) {
        html = '<div class="step-time-distance" style="left: ' + left + 'px;' + helper_color_for_time_in_floor(distance) + '">' + helper_format_secons_to_min_hours(distance) + '.</div>';
    }

    return html;

}

window.helper_format_secons_to_min_hours = (distance) => {
    if (distance < 60) {
        return distance + " seg"
    } else if (distance > 60 && distance < 3600) {
        return parseInt(distance/60) + " min"
    } else if (distance > 3600){
        return parseInt(distance/3600) + " h"
    }
}

window.helper_color_for_time_in_floor = (distance) => {
    if( distance < 300 ) {
        return " background-color: red; font-weight: bold;";
    } else {
        return " background-color: green";
    }
}

window.return_relocation_alert_colors = (alert) => {
    if (alert == 1) {
        return "orange";
    } else if (alert == 2) {
        return "blue";
    } else if (alert == 3) {
        return "red";
    } else {
        return "green";
    }
}

window.api_v1_cono_details = (data) => {

    spin_in();
    const auth_token = localStorage.getItem('auth_token');
    fetch("/es/carfinder/api/v1/cono_details",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(data),
        },
        10000
    )
    .then((response) => { return response.json() })
    .then((data) => {
        console.log(data);

        if( data.status == 200 ) {
            spin_out();

            const steps = data.data;

            let html_str = '';
            if ( steps.length > 0 ) {
                
                for ( let i=0; i<steps.length; i++ ) {
                    const step = steps[i];
                    html_str += '<div class="col-3">';
                    html_str += '<div class="cono-details-cono-wrapper color-' + return_css_color_from_cono_color (step.tag_color) + '">';
                    html_str += '<div class="cono-details-car-wrapper" style="background-color: ' + return_css_color_from_cono_color(step.tag_color) + '">';
                    html_str += '<span class="car-icon-number">' + (i+1) + 'º - <i class="fas fa-car fa-1x"></i> - ' + step.tag_number + '</span> <br>';
                    html_str += 'Entra: ' + step.start + '<br>';
                    html_str += 'Sale: ' + step.stop + '';
                    html_str += '</div>';
                    html_str += '<div class="cono-details-list-wrapper">';
                    html_str += '<div class="cono-details-list-uses">';
                    html_str += '<div class="vertical-line"></div>';

                    const moves = step.moves;
                    if ( moves.length > 0 ) {
                        for ( let j=0; j<moves.length; j++ ) {
                            const move = moves[j]; 
                            html_str += '<span class="cono-details-planta">' + move.location + '</span> ';
                            html_str += '<span class="cono-details-fecha">' + move.start + '</span>';
                            html_str += '<span class="cono-details-distance" style="' + helper_color_for_time_in_floor(move.distance_secs) + '">' + move.distance + '</span>';
                            
                            if ( move.distance != "Último" ) {
                                html_str += '<svg height="60" width="100%" style=" margin-left: 30px;">';
                                html_str += '<polyline points="30,10 40,20 50,10" style="fill:none;stroke:green;stroke-width:5"></polyline>';
                                html_str += '<polyline points="10,10 40,40 70,10" style="fill:none;stroke:green;stroke-width:10"></polyline>';
                                html_str += '</svg>';
                            }

                        }
                    }

                    html_str += '</div>';
                    html_str += '</div>';
                    html_str += '</div>';
                    html_str += '</div>';

                    $(".display-cono-details").html(html_str);
                }

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "El cono solicitado, no tiene usos todavía."
                });
            }

        } else if ( data.status == 500 ) {
            spin_out();
            sweetalert_error('Error de ejecución', 'Error code: E014', data.error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
        } else if ( data.status == 401 ) {
            spin_out();
            sweetalert_error_401();
        }
        
    })
    .catch((error) => {
        spin_out();
        console.log(error);
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E009', error+'. Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

}

