// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "packs/helpers"
// import "packs/fetch_with_timeout"
import "packs/engines/lihtlogin/lihtlogin"
import "packs/engines/carfinder/carfinder"


Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '../css/main.scss'

import '@fortawesome/fontawesome-free/js/all'
import Swal from 'sweetalert2'
window.Swal = Swal

import Cookie from "js.cookie"
window.Cookie = Cookie

import Validate from 'validate.js'
window.Validate = Validate
