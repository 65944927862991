import consumer from "./consumer"

consumer.subscriptions.create("ReadsChannel", {
    connected() {
        console.log("Connected to the reads_channel");
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        console.log(data);

        const move = data.move;
        const uuid = move.cono;

        let html_str = '';
        if ( move.status == 200 ) {
            if ( move.driving.response === undefined ) {
                $(".display-moves").css('background-color', '#778aac');
                html_str += '<div class="col-12">'+return_tag_uuid_human_readable(uuid)+' : '+move.use_id+'</div>';
                html_str += '<div class="col-12">De '+move.from+' a '+move.to+' </div>';
            } else {
                if ( move.driving.action ) {
                    $(".display-moves").css('background-color', '#5b8d2a');
                    html_str += '<div class="col-12">'+return_tag_uuid_human_readable(uuid)+'</div>';
                    html_str += '<div class="col-12">'+move.driving.info+' </div>';
                } else {
                    $(".display-moves").css('background-color', '#a34c49');
                    html_str += '<div class="col-12">'+return_tag_uuid_human_readable(uuid)+'</div>';
                    html_str += '<div class="col-12">'+move.driving.info+' </div>';
                }
            }
        } else {
            $(".display-moves").css('background-color', 'orange');
            html_str += '<div class="col-12">'+return_tag_uuid_human_readable(uuid)+' : '+move.use_id+'</div>';
            html_str += '<div class="col-12">Estaba '+move.from+' leido por '+move.to+' </div>';
        }

        $(".display-moves").html(html_str);
        $(".display-moves").slideDown();

        setTimeout(() => {
            $(".display-moves").fadeOut();
        }, 4000);

    }
});
