import consumer from "./consumer"

consumer.subscriptions.create("StatisticsChannel", {
    connected() {
        console.log("Connected to the statistics");
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        console.log('Recibiendo datos de la app');
        console.log(data);

        // Grow vertical lines
        $(".measure-red").css('height', data.red.average.toFixed(2)+'%')
        $(".measure-green").css('height', data.green.average.toFixed(2)+'%')
        $(".measure-yellow").css('height', data.yellow.average.toFixed(2)+'%')
        $(".measure-blue").css('height', data.blue.average.toFixed(2)+'%')

        $(".red-free").text(data.red.free);
        $(".red-used").text(data.red.used);

        $(".green-free").text(data.green.free);
        $(".green-used").text(data.green.used);

        $(".yellow-free").text(data.yellow.free);
        $(".yellow-used").text(data.yellow.used);

        $(".blue-free").text(data.blue.free);
        $(".blue-used").text(data.blue.used);

        $(".all-average").text(data.all_average.toFixed(2)+' %');

    }
});
