// helpers.js

/****** Helpers for all app */
window.sweetalert_error = (text, subject, body) => {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: text,
        footer: 'Si el problema persiste <a href="mailto:cs@liht.com?subject=' + subject + '&body=' + body + '"> <strong> PULSE AQUI</strong></a>'
    })
}

window.sweetalert_error_401 = () => {
    Swal.fire({
        icon: 'error',
        title: 'Login',
        text: 'Su sesión ha caducado o no esta correctamente logeado.',
        footer: '<a href="/"> <strong> PULSE AQUI</strong></a> para ir a la página de acceso'
    })
}

window.sweetalert_error_for_actions = (title, text, footer) => {
    Swal.fire({
        icon: 'error',
        title: title,
        text: text,
        footer: footer
    })
}

window.go_to_login = () => {
    window.location.href = '/';
}


/*** Helpers for carfinder */
window.return_css_color_from_cono_color = (c) => {
    if( c == "ROJO" ) {
        return "red";
    } else if( c == "AMAR" ) {
        return "orange";
    } else if( c == "GREE" ) {
        return "green";
    } else if( c == "AZUL" ) {
        return "blue";
    }
}

window.return_css_color_from_cono_color_black_theme = (c) => {
    if( c == "ROJO" ) {
        return "#ff7979";
    } else if( c == "AMAR" ) {
        return "orange";
    } else if( c == "GREE" ) {
        return "#80ff80";
    } else if( c == "AZUL" ) {
        return "#7575ff";
    }
}

window.return_tag_uuid_human_readable = (uuid) => {
    const color = uuid.substring(0,4);
    const num = parseInt(uuid.substring(8,11)).toString();
    if( color == "ROJO" ) {
        return "Rojo-"+num;
    } else if( color == "AMAR" ) {
        return "Amarillo-"+num;
    } else if( color == "GREE" ) {
        return "Verde-"+num;
    } else if( color == "AZUL" ) {
        return "Azul-"+num;
    }
}

window.return_floor_human_readable = (floor) => {
    switch (parseInt(floor)) {
        case 1:
            return "Planta 0";
            break;
        case 4:
            return "Planta +1";
            break;
        case 5:
            return "Planta -1";
            break;
        case 6:
            return "Planta -2";
            break;
    }
}

window.restore_screen = ()=> {

    $(".search-display").hide();
    $(".app-display").show();

    $(".number-val").css('background-color', 'gainsboro').css('color', 'rgb(31, 53, 94)');
    $(".print-car").html('<i class="fas fa-car fa-6x"></i>').css('color', 'white');
    $(".print-num").empty();
    $("#cono").val('');
    $("#color").val('');
    $("#function-status").val('0');

    // Update action buttons on every click
    check_action_button_status();
}

window.restore_screen_after_relocation = ()=> {

    $(".search-display").hide();
    $(".app-display").show();

    $(".number-val").css('background-color', 'gainsboro').css('color', 'rgb(31, 53, 94)');
    $(".print-car").html('<i class="fas fa-car fa-6x"></i>').css('color', 'white');
    $(".print-num").empty();
    $("#cono").val('');
    $("#color").val('');
    $("#function-status").val('0');
    if ( !$(".btn-pack").hasClass('action-btn') ) {
        $(".btn-pack").addClass('action-btn')
    }
}

window.restore_after_search = ()=> {
    $(".number-val").css('background-color', 'gainsboro').css('color', 'rgb(31, 53, 94)');
    $("#cono").val('');
    $("#color").val('');

    // Update action buttons on every click
    check_action_button_status();
}

window.check_function_status = () => {
    if ( $("#function-status").val() != 0 ) {
        restore_screen();
    }
}


window.check_action_button_status = () => {
    const cono = $("#cono").val();
    const color = $("#color").val();

    if ( cono != "" && color != "" ) {
        $(".action-btn").addClass('action-btn-on');
    } else {
        $(".action-btn").removeClass('action-btn-on');
    }
}

// Spinner Helpers
window.spin_in = () => { $(".spinner-bg").fadeIn(200) }
window.spin_out = () => { $(".spinner-bg").fadeOut(200) }
window.check_data_for_action_execution = () => {Swal.fire('ERROR: Faltan datos', 'Debe tener un número de cono y un color seleccionado')}


// Execution helpers
window.is_executable = (btn) => {
    if ( btn.hasClass('action-btn-on') ){
        return true;
    } else {
        return false;
    }
}

// Time counter
window.start_crono = () => {
    let time = {
        hour: 0,
        minute: 0,
        second: 0
    };

    localStorage.setItem('reset_crono', false);

    let sec, min, ho, reset;

    let loop_cono = setInterval(() => {
        reset = JSON.parse(localStorage.getItem('reset_crono'));
        if ( reset ) {
            time = {
                hour: 0,
                minute: 0,
                second: 0
            };
            $(".tag-crono").show();
            localStorage.setItem('reset_crono', false);
        }

        // increase 1 per secons
        time.second++;
        // Segundos
        if(time.second >= 60){
            time.second = 0;
            time.minute++;
        }      
        // Minutos
        if(time.minute >= 60){
            time.minute = 0;
            time.hour++;
        }

        sec = (time.second < 10 ? '0' + time.second : time.second);
        min = (time.minute < 10 ? '0' + time.minute : time.minute);
        ho = (time.hour < 10 ? '0' + time.hour : time.hour);

        $(".tag-crono").text(ho + ':' + min + ':' + sec);

        loop_cono;

    }, 1000);


}
window.reset_crono = () => {
    localStorage.setItem('reset_crono', true);
}

window.translate_reader_id = (reader_id) => {
    if( reader_id == 50 ) {
        return "+1";
    } else if( reader_id == 51 ) {
        return "-1";
    } else if( reader_id == 52 ) {
        return "-2";
    } else {
        return "-";
    }
}

window.redirect_between_pages = (uri) => {
    let str = '';
    str += '<form action="'+uri+'" method="post" id="redirect-form">';
    str += '<input type="hidden" name="auth_token" value="' + localStorage.getItem('auth_token') + '">';
    str += '</form>';
    $("#redirect-form-wrapper").html(str);

    $("#redirect-form").submit();
}

window.return_uuid_format_from_color_number = (color, number) => {
    num = number.toString();
    switch (num.length) {
        case 1:
            return color+"000000"+num;
            break;
        case 2:
            return color+"00000"+num;
        default:
            return color+"0000"+num;
    }
}

window.return_relocation_alert_colors = (alert) => {
    switch (alert) {
        case 1:
            return "orange";
            break;
        case 2:
            return "red";
        default:
            return "green";
    }
}

window.validate_cono_number = (number) => {
    if ( isNaN(parseInt(number)) ) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Debe escribir un numero entre el 1 y 150 para amarillo y rojo, y entre 1 y 100 para verdes y azules."
        });
        return false;
    } else {
        if ( parseInt(number) < 1 && parseInt(number) > 150 ) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Debe escribir un numero entre el 1 y 150 para amarillo y rojo, y entre 1 y 100 para verdes y azules."
            });
            return false;
        } else {
            return true;
        }
    }
}

