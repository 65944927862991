// lihtlogin.js

// import fetch from '../../fetch_with_timeout'

window.get_login = (data) => {
    spin_in();
    fetch("/es/api/login/login",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                //Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(data),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            Swal.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: 'Error en usuario y/o contraseña'
            })
        } else {
            localStorage.setItem('auth_token', data.auth_token);
            let str = '';
            str += '<form action="/es/carfinder/navigators/index" method="post" id="redirect-form">';
            str += '<input type="hidden" name="auth_token" value="' + data.auth_token + '">';
            str += '</form>';
            $("#redirect").html(str);

            $("#redirect-form").submit();
        }

    })
    .catch((error) => {
        spin_out();
        sweetalert_error('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.get_remember = (data) => {
    console.log(data);
}

window.logout = () => {
    localStorage.removeItem('auth_token');
    window.location.href = '/'
}

